const BuildTab = () => (
  <div className="space-y-6 text-center">
    <h2 className="text-2xl font-bold text-gray-800">
      Ready to Turn Your Domain into a Beautiful Website?
    </h2>
    <p className="text-lg text-gray-600 max-w-2xl mx-auto">
      Take the next step in your online journey! With our partner Zarla, you can create a professional website in seconds - no coding required.
    </p>
    <div className="space-y-4 mt-6">
      <div className="bg-blue-50 p-6 rounded-lg">
        <ul className="text-left space-y-3 mb-6">
          <li className="flex items-center">
            <span className="mr-2">🚀</span>
            Describe your business and your website is ready to publish in seconds!
          </li>
          <li className="flex items-center">
            <span className="mr-2">✨</span>
            Professional templates designed for your industry
          </li>
          <li className="flex items-center">
            <span className="mr-2">📱</span>
            Mobile-responsive designs that looks good in all devices
          </li>
        </ul>
      </div>
      <a
        href="https://www.zarla.com/?ref=domainnamegeneratorai"
        target="_blank"
        rel="noopener noreferrer"
        className="inline-block px-8 py-4 bg-blue-600 text-white rounded-lg text-lg font-semibold hover:bg-blue-700 transition-colors"
      >
        Start Building Your Website Now
      </a>
    </div>
  </div>
);

export default BuildTab;