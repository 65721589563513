import React, { useState, useEffect } from 'react';

const CookieConsent = ({ onPreferencesChange }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [preferences, setPreferences] = useState({
    analytics: false
  });

  useEffect(() => {
    const savedPreferences = localStorage.getItem('cookiePreferences');
    if (savedPreferences) {
      const parsed = JSON.parse(savedPreferences);
      setPreferences(parsed);
      onPreferencesChange(parsed);
    } else {
      setShowDialog(true);
    }
  }, []);

  const handleSave = () => {
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    onPreferencesChange(preferences);
    setShowDialog(false);
  };

  const handleRejectAll = () => {
    const newPreferences = {
      analytics: false
    };
    setPreferences(newPreferences);
    localStorage.setItem('cookiePreferences', JSON.stringify(newPreferences));
    onPreferencesChange(newPreferences);
    setShowDialog(false);
  };

  // Button styles
  const buttonBaseStyle = {
    padding: '10px 20px',
    borderRadius: '6px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: '500',
    transition: 'all 0.2s ease',
    outline: 'none',
  };

  const primaryButtonStyle = {
    ...buttonBaseStyle,
    background: '#2563eb',
    color: 'white',
    border: 'none',
  };

  const secondaryButtonStyle = {
    ...buttonBaseStyle,
    background: 'white',
    border: '1px solid #e5e7eb',
    color: '#374151',
  };

  if (!showDialog) {
    return null; // Don't render anything if dialog is hidden
  }

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      backdropFilter: 'blur(4px)',
      WebkitBackdropFilter: 'blur(4px)',
    }}>
      <div style={{
        background: 'white',
        padding: '32px',
        borderRadius: '12px',
        maxWidth: '500px',
        width: '90%',
        maxHeight: '90vh',
        overflow: 'auto',
        boxShadow: '0 20px 25px -5px rgba(0,0,0,0.1), 0 10px 10px -5px rgba(0,0,0,0.04)',
        position: 'relative',
      }}>
        <h2 style={{
          marginTop: 0,
          fontSize: '24px',
          color: '#111827',
          fontWeight: '600',
          marginBottom: '12px'
        }}>
          Cookie Settings
        </h2>
        <p style={{
          color: '#6b7280',
          fontSize: '15px',
          lineHeight: '1.5',
          marginBottom: '24px'
        }}>
          We value your privacy. Choose your cookie preferences below.
        </p>

        <div style={{ marginTop: '20px' }}>
          {/* Essential Features Info */}
          <div style={{
            background: '#f3f4f6',
            padding: '16px',
            borderRadius: '8px',
            marginBottom: '24px',
            border: '1px solid #e5e7eb',
          }}>
            <h3 style={{
              margin: '0 0 8px 0',
              fontSize: '16px',
              color: '#111827',
              fontWeight: '600'
            }}>
              Essential Features
            </h3>
            <p style={{
              margin: 0,
              color: '#6b7280',
              fontSize: '14px',
              lineHeight: '1.5'
            }}>
              Our system stores anonymous interaction data without any personal information.
              This core functionality cannot be disabled and doesn't track individual users.
            </p>
          </div>

          {/* Analytics Toggle */}
          <div style={{
            marginBottom: '32px',
            padding: '16px',
            border: '1px solid #e5e7eb',
            borderRadius: '8px',
            background: '#ffffff'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <div>
                <h3 style={{
                  margin: '0 0 4px 0',
                  fontSize: '16px',
                  color: '#111827',
                  fontWeight: '600'
                }}>
                  Analytics Cookies
                </h3>
                <p style={{
                  margin: 0,
                  color: '#6b7280',
                  fontSize: '14px',
                  lineHeight: '1.5'
                }}>
                  Help us understand how visitors interact with our website
                </p>
              </div>
              <label className="switch" style={{ position: 'relative' }}>
                <input
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={(e) => setPreferences(prev => ({
                    ...prev,
                    analytics: e.target.checked
                  }))}
                  style={{
                    width: '44px',
                    height: '24px',
                    appearance: 'none',
                    background: preferences.analytics ? '#2563eb' : '#e5e7eb',
                    borderRadius: '24px',
                    cursor: 'pointer',
                    transition: 'background 0.3s ease',
                    position: 'relative',
                    boxShadow: 'inset 0 1px 2px rgba(0,0,0,0.1)',
                  }}
                />
                <span style={{
                  position: 'absolute',
                  top: '2px',
                  left: preferences.analytics ? '22px' : '2px',
                  width: '20px',
                  height: '20px',
                  background: 'white',
                  borderRadius: '50%',
                  transition: 'left 0.3s ease',
                  boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
                  pointerEvents: 'none',
                }}/>
              </label>
            </div>
          </div>

          {/* Buttons */}
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '12px',
            borderTop: '1px solid #e5e7eb',
            paddingTop: '24px',
          }}>
            <button
              onClick={handleRejectAll}
              style={secondaryButtonStyle}
              onMouseEnter={e => {
                e.currentTarget.style.background = '#f9fafb';
                e.currentTarget.style.borderColor = '#d1d5db';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.background = 'white';
                e.currentTarget.style.borderColor = '#e5e7eb';
              }}
            >
              Reject All
            </button>
            <button
              onClick={handleSave}
              style={primaryButtonStyle}
              onMouseEnter={e => {
                e.currentTarget.style.background = '#1d4ed8';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.background = '#2563eb';
              }}
            >
              Save Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;