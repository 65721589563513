const LoadingSpinner = () => (
  <div className="flex items-center justify-center p-4">
    <div
      className="w-8 h-8 border-4 border-gray-200 rounded-full"
      style={{
        borderTopColor: '#2563eb',
        animation: 'spin 1s linear infinite'
      }}
    />
    <style jsx>{`
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
    `}</style>
  </div>
);

export default LoadingSpinner;