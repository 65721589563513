import { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { useDomainApi } from '../hooks/useDomainApi';
import { useDomainContext } from '../contexts/DomainContext';

const AnalyzeTab = () => {
  const [domainInput, setDomainInput] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const { analysis, setAnalysis } = useDomainContext();
  const { analyzeDomain } = useDomainApi();

  const handleAnalyze = async () => {
    if (domainInput.length < 4) return;

    try {
      setIsAnalyzing(true);
      const analysisResult = await analyzeDomain(domainInput);
      setAnalysis(analysisResult);
    } catch (error) {
      console.error('Error in handleAnalyze:', error);
    } finally {
      setIsAnalyzing(false);
    }
  };

  return (
    <div className="space-y-4">
      <input
        type="text"
        placeholder="Enter a domain name to analyze"
        value={domainInput}
        onChange={(e) => setDomainInput(e.target.value)}
        className="w-full p-3 border rounded-lg"
      />
      <button
        onClick={handleAnalyze}
        disabled={domainInput.length < 4 || isAnalyzing}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50"
      >
        {isAnalyzing ? 'Analyzing...' : 'Analyze Domain'}
      </button>

      {isAnalyzing ? (
        <div className="mt-8">
          <LoadingSpinner />
        </div>
      ) : (
        analysis && (
          <div className="mt-6 p-6 border rounded-lg shadow">
            <div className="flex flex-col justify-between mb-6">
              <h3 className="text-xl font-semibold">{analysis.domain}</h3>
              <div className={`px-4 py-2 rounded-full ${
                analysis.score >= 80 ? 'bg-green-100 text-green-800' :
                analysis.score >= 60 ? 'bg-yellow-100 text-yellow-800' :
                'bg-red-100 text-red-800'
              }`}>
                Score: {analysis.score}/100
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-6">
              <div>
                <div className="font-medium mb-3">Pros</div>
                <ul className="space-y-2">
                  {analysis.pros.map((pro, index) => (
                    <li key={index}>• {pro}</li>
                  ))}
                </ul>
              </div>
              <div>
                <div className="font-medium mb-3">Cons</div>
                <ul className="space-y-2">
                  {analysis.cons.map((con, index) => (
                    <li key={index}>• {con}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default AnalyzeTab;