import { useState, useCallback } from 'react';

const API_BASE_URL = '/api';

export const useDomainApi = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingReaction, setLoadingReaction] = useState(null);

  const generateSuggestions = useCallback(async (description) => {
    try {
      const response = await fetch(`${API_BASE_URL}/domains/suggest`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ description })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error generating domain suggestions:', error);
      const words = description.toLowerCase().split(' ').filter(word => word.length > 3);
      return [
        { domain: `${words[0]}.com`, confidence: 0.9 },
        { domain: `${words[0]}${words[1] || ''}.com`, confidence: 0.8 },
        { domain: `the${words[0]}.com`, confidence: 0.7 },
        { domain: `${words[0]}.io`, confidence: 0.6 },
        { domain: `${words[0]}-${words[1] || 'app'}.com`, confidence: 0.5 }
      ];
    }
  }, []);

  const analyzeDomain = useCallback(async (domain) => {
    try {
      const response = await fetch(`${API_BASE_URL}/domains/analyze`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ domain })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || `HTTP error! status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error generating domain analysis:', error);
      return {
        domain,
        pros: [
          'Easy to remember',
          'Appropriate length',
          'Common TLD (.com)',
          'Brandable name',
          'No special characters'
        ],
        cons: [
          'Might be expensive',
          'Similar domains exist',
          'Could be confused with similar names',
          'May be trademarked',
          'Meaning might not be clear'
        ],
        score: Math.floor(Math.random() * 40 + 60)
      };
    }
  }, []);

const handleReaction = useCallback(async (domain, type, requestId) => {
  try {
    setLoadingReaction(`${type}-${domain}`);

    const response = await fetch(`${API_BASE_URL}/domains/${type}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        domain,
        request_id: requestId
      })
    });

    if (!response.ok) {
      throw new Error(`Failed to ${type} domain`);
    }

    return true;
  } catch (error) {
    console.error(`Error ${type}ing domain:`, error);
    return false;
  } finally {
    setLoadingReaction(null);
  }
}, []); // Add any dependencies to the dependency array if needed

  return {
    generateSuggestions,
    analyzeDomain,
    handleReaction,
    isLoading,
    setIsLoading,
    loadingReaction
  };
};