import { createContext, useContext, useState } from 'react';

const DomainContext = createContext(null);

export const DomainProvider = ({ children }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [analysis, setAnalysis] = useState(null);
  const [requestId, setRequestId] = useState(null);


  return (
    <DomainContext.Provider value={{
      suggestions,
      setSuggestions,
      analysis,
      setAnalysis,
      requestId,
      setRequestId
    }}>
      {children}
    </DomainContext.Provider>
  );
};

export const useDomainContext = () => {
  const context = useContext(DomainContext);
  if (!context) {
    throw new Error('useDomainContext must be used within a DomainProvider');
  }
  return context;
};