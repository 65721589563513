import { useState } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { useDomainApi } from '../hooks/useDomainApi';
import { useDomainContext } from '../contexts/DomainContext';

const SuggestionTab = () => {
  const [description, setDescription] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const { suggestions, setSuggestions, requestId, setRequestId } = useDomainContext();
  const { generateSuggestions, handleReaction, loadingReaction } = useDomainApi();

  const handleSuggest = async () => {
    if (description.length < 10) return;

    try {
      setIsGenerating(true);
      const response = await generateSuggestions(description);
      // Update both suggestions and requestId from the new API response
      setSuggestions(response.suggestions);
      setRequestId(response.request_id);
    } catch (error) {
      console.error('Error in handleSuggest:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleLike = async (domain) => {
  // Only proceed if requestId is available
  if (!requestId) {
    console.error('No request ID available for reaction');
    return;
  }

  const success = await handleReaction(domain, 'like', requestId);
  if (success) {
    setSuggestions(suggestions.map(suggestion =>
      suggestion.domain === domain
        ? { ...suggestion, liked: true, disliked: false }
        : suggestion
    ));
  }
};

  const handleDislike = async (domain) => {
  // Only proceed if requestId is available
  if (!requestId) {
    console.error('No request ID available for reaction');
    return;
  }

  const success = await handleReaction(domain, 'dislike', requestId);
  if (success) {
    setSuggestions(suggestions.map(suggestion =>
      suggestion.domain === domain
        ? { ...suggestion, liked: false, disliked: true }
        : suggestion
    ));
  }
};

  const getCloakedLink = (domain) =>
    `/go/namecheap-domain?domain=${encodeURIComponent(domain)}${requestId ? `&request_id=${requestId}` : ''}`;

  return (
    <div className="space-y-4">
      <textarea
        placeholder="Describe your website in detail (e.g., purpose, target audience, key features)"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        className="w-full p-3 border rounded-lg h-32"
      />
      <button
        onClick={handleSuggest}
        disabled={description.length < 10 || isGenerating}
        className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg disabled:opacity-50"
      >
        {isGenerating ? 'Generating...' : 'Generate Suggestions'}
      </button>

      {isGenerating ? (
        <div className="mt-8">
          <LoadingSpinner />
        </div>
      ) : (
        suggestions.length > 0 && (
          <div className="space-y-4 mt-6">
            <h3 className="text-xl font-semibold">Suggested Domains</h3>
            {suggestions.map((suggestion) => (
              <div key={suggestion.id} className="p-4 border rounded-lg shadow">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
                  <span className="text-lg font-medium">{suggestion.domain}</span>
                  <div className="flex flex-wrap items-center gap-4">
                    <div className={`px-3 py-1 rounded-full text-sm ${
                      suggestion.confidence > 0.7 ? 'bg-green-100 text-green-800' :
                      suggestion.confidence > 0.4 ? 'bg-yellow-100 text-yellow-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {Math.round(suggestion.confidence * 100)}% Match
                    </div>
                    <div className={`px-3 py-1 rounded-full text-sm ${
                      suggestion.has_dns ? 'bg-red-100 text-red-800' : 'bg-green-100 text-green-800'
                    }`}>
                      {suggestion.has_dns ? 'Not Available' : (
                        <a
                          href={getCloakedLink(suggestion.domain)}
                          target="_blank"
                          rel="noopener"
                          className="hover:underline"
                        >
                          Available - Register Now
                        </a>
                      )}
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() => handleLike(suggestion.domain)}
                        disabled={loadingReaction === `like-${suggestion.domain}`}
                        className={`p-2 rounded ${suggestion.liked ? 'bg-green-100 text-green-600' : 'hover:bg-gray-100'}`}
                      >
                        👍
                      </button>
                      <button
                        onClick={() => handleDislike(suggestion.domain)}
                        disabled={loadingReaction === `dislike-${suggestion.domain}`}
                        className={`p-2 rounded ${suggestion.disliked ? 'bg-red-100 text-red-600' : 'hover:bg-gray-100'}`}
                      >
                        👎
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
      )}
    </div>
  );
};

export default SuggestionTab;