import { useState, memo } from 'react';
import SuggestionTab from './components/SuggestionTab';
import AnalyzeTab from './components/AnalyzeTab';
import BuildTab from './components/BuildTab';
import { DomainProvider } from './contexts/DomainContext';

const TabButton = memo(({ id, label, activeTab, onClick }) => (
  <button
    onClick={() => onClick(id)}
    className={`
      w-full sm:w-40
      px-4 py-2
      rounded-lg
      text-center
      transition-colors
      ${activeTab === id
        ? 'bg-blue-600 text-white'
        : 'bg-gray-200 hover:bg-gray-300'
      }
    `}
  >
    {label}
  </button>
));

const DomainAdvisor = () => {
  const [activeTab, setActiveTab] = useState('suggest');

  const tabs = [
    { id: 'suggest', label: 'Get Suggestions' },
    { id: 'analyze', label: 'Analyze Domain' },
    { id: 'build', label: 'Build Your Website' }
  ];

  return (
    <DomainProvider>
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-lg">
        <div className="flex flex-col justify-center sm:flex-row gap-2 sm:gap-4 p-4 border-b">
          {tabs.map(tab => (
            <TabButton
              key={tab.id}
              id={tab.id}
              label={tab.label}
              activeTab={activeTab}
              onClick={setActiveTab}
            />
          ))}
        </div>
        <div className="p-4 sm:p-6">
          {activeTab === 'suggest' && <SuggestionTab />}
          {activeTab === 'analyze' && <AnalyzeTab />}
          {activeTab === 'build' && <BuildTab />}
        </div>
      </div>
    </DomainProvider>
  );
};

export default DomainAdvisor;