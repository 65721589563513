import React from 'react';
import ReactDOM from 'react-dom';
import DomainAdvisor from './DomainAdvisor';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import CookieConsent from './CookieConsent'; // Add this import

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

// Create App wrapper component
const App = () => {
    const handlePreferencesChange = (preferences) => {
        if (preferences.analytics) {
            // Initialize Google Analytics
            // Example (replace with your GA code):
            if (window.gtag) {
                window.gtag('consent', 'update', {
                    'analytics_storage': 'granted'
                });
            }
        } else {
            // Disable Google Analytics
            if (window.gtag) {
                window.gtag('consent', 'update', {
                    'analytics_storage': 'denied'
                });
            }
        }

        if (preferences.userActions) {
            // Initialize your user action tracking
            // Add your tracking initialization code here
        }
    };

    return (
        <>
            <DomainAdvisor />
            <CookieConsent onPreferencesChange={handlePreferencesChange} />
        </>
    );
};

// Mount the React component using your existing method
const rootElement = document.getElementById('domain-advisor-app');

if (!rootElement) {
    console.error('Could not find root element');
}


try {
    const root = ReactDOM.createRoot(rootElement);
    root.render(<App />);
} catch (error) {
    console.error('Rendering error:', error);
}

// Your existing GSAP animations
gsap.from(".hero-content", {
    duration: 1,
    y: 50,
    opacity: 0,
    ease: "power3.out"
});

gsap.from(".feature-card", {
    duration: 0.8,
    y: 30,
    opacity: 0,
    stagger: 0.2,
    scrollTrigger: {
        trigger: ".features",
        start: "top center"
    }
});